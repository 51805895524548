var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wxaTemplate", attrs: { id: "wrap" } },
    [
      _c(
        "cardTitleCom",
        {
          attrs: {
            cardTitle:
              "微信小程序模板列表(" +
              (_vm.curTemplate === "templatedRaf" ? "草稿" : "模板") +
              ")",
          },
        },
        [
          _c("template", { slot: "cardContent" }, [
            _c(
              "div",
              { staticClass: "tableContent" },
              [
                _c(
                  "operatingButton",
                  {
                    attrs: {
                      getList: _vm.clickBtn,
                      multiple: _vm.multiple,
                      isDeleteDisabled: _vm.curTemplate === "templatedRaf",
                      isCopyBtn: true,
                      isEnableBtn: true,
                    },
                    on: {
                      handleAdd: function ($event) {
                        return _vm.clickBtn("新增")
                      },
                      handleDelete: function ($event) {
                        return _vm.clickBtn("删除")
                      },
                    },
                  },
                  [
                    _c(
                      "template",
                      { slot: "specialDiyBtn" },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              type: "primary",
                              icon: "el-icon-refresh",
                              size: "mini",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.clickBtn("查询草稿")
                              },
                            },
                          },
                          [_vm._v("查询草稿 ")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: {
                              icon: "el-icon-copy-document",
                              type: "success",
                              size: "mini",
                              disabled: _vm.curTemplate === "templated",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.clickBtn("生成模板")
                              },
                            },
                          },
                          [_vm._v("生成模板 ")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: {
                              type: "primary",
                              icon: "el-icon-refresh",
                              size: "mini",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.clickBtn("查询模板")
                              },
                            },
                          },
                          [_vm._v("查询模板 ")]
                        ),
                        _c(
                          "el-dropdown",
                          {
                            staticClass: "marL10 marR10",
                            attrs: {
                              "split-button": "",
                              size: "mini",
                              plain: "",
                              icon: "el-icon-circle-check",
                              type: "warning",
                              disabled: _vm.curTemplate === "templatedRaf",
                            },
                            on: { command: _vm.settingTemplate },
                          },
                          [
                            _vm._v(" 设置小程序模板 "),
                            _c(
                              "el-dropdown-menu",
                              { attrs: { slot: "dropdown" }, slot: "dropdown" },
                              _vm._l(
                                _vm.dict.type.wx_app_type,
                                function (dict) {
                                  return _c(
                                    "el-dropdown-item",
                                    {
                                      key: dict.value,
                                      attrs: { command: dict.value },
                                    },
                                    [_vm._v(_vm._s(dict.label) + " ")]
                                  )
                                }
                              ),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  2
                ),
                _c(
                  "div",
                  { staticClass: "table" },
                  [
                    _vm.curTemplate === "templated"
                      ? _c(
                          "el-table",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.loading,
                                expression: "loading",
                              },
                            ],
                            ref: "table",
                            staticStyle: { width: "100%" },
                            attrs: {
                              data: _vm.tableData,
                              height: _vm.tableHeight,
                              "max-height": _vm.tableHeight,
                              border: "",
                              "element-loading-text": "加载中",
                            },
                            on: { "row-click": _vm.handleRowClick },
                          },
                          [
                            _c("el-table-column", {
                              attrs: { width: "55", align: "center" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "el-radio",
                                          {
                                            attrs: {
                                              label: scope.row.template_id,
                                            },
                                            model: {
                                              value: _vm.curId,
                                              callback: function ($$v) {
                                                _vm.curId = $$v
                                              },
                                              expression: "curId",
                                            },
                                          },
                                          [_c("span")]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                3528664326
                              ),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                type: "index",
                                label: "序号",
                                width: "50",
                                align: "center",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                "show-overflow-tooltip": "",
                                prop: "create_time",
                                label: "创建时间",
                                width: "260",
                                align: "center",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                "show-overflow-tooltip": "",
                                prop: "user_version",
                                label: "版本号",
                                width: "150",
                                align: "center",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                "show-overflow-tooltip": "",
                                prop: "user_desc",
                                label: "模板描述",
                                align: "center",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                "show-overflow-tooltip": "",
                                label: "当前版本",
                                width: "150",
                                align: "center",
                                prop: "version",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                "show-overflow-tooltip": "",
                                prop: "template_id",
                                label: "模板ID",
                                width: "130",
                                align: "center",
                              },
                            }),
                          ],
                          1
                        )
                      : _c(
                          "el-table",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.loading,
                                expression: "loading",
                              },
                            ],
                            ref: "table",
                            staticStyle: { width: "100%" },
                            attrs: {
                              data: _vm.tableData,
                              height: _vm.tableHeight,
                              "max-height": _vm.tableHeight,
                              border: "",
                            },
                            on: { "row-click": _vm.handleRowClick },
                          },
                          [
                            _c("el-table-column", {
                              attrs: { width: "55", align: "center" },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "el-radio",
                                        {
                                          attrs: { label: scope.row.draft_id },
                                          model: {
                                            value: _vm.curId,
                                            callback: function ($$v) {
                                              _vm.curId = $$v
                                            },
                                            expression: "curId",
                                          },
                                        },
                                        [_c("span")]
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                type: "index",
                                label: "序号",
                                width: "50",
                                align: "center",
                              },
                            }),
                            _vm._l(_vm.tableFields, function (item) {
                              return _c("el-table-column", {
                                key: item.title,
                                attrs: {
                                  "show-overflow-tooltip": "",
                                  prop: item.prop,
                                  label: item.title,
                                  width: item.width,
                                  align: "center",
                                },
                              })
                            }),
                          ],
                          2
                        ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }