var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "wrap",
    },
    [
      _c("TablePage", {
        ref: "tablePage",
        on: { handleEvent: _vm.handleEvent },
        model: {
          value: _vm.options,
          callback: function ($$v) {
            _vm.options = $$v
          },
          expression: "options",
        },
      }),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.openDialog, width: "30%", title: _vm.title },
          on: {
            "update:visible": function ($event) {
              _vm.openDialog = $event
            },
          },
        },
        [_c("img", { attrs: { width: "100%", src: _vm.QRCodeImg, alt: "" } })]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }