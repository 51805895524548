var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "platSms", attrs: { id: "wrap" } },
    [
      _c("cardTitleCom", {
        staticClass: "productMenu",
        attrs: { cardTitle: "基础信息" },
        scopedSlots: _vm._u([
          {
            key: "cardContent",
            fn: function () {
              return [
                _c(
                  "div",
                  {
                    staticClass: "cardContent",
                    style: { height: _vm.tableHeight + "px" },
                  },
                  [
                    _c(
                      "el-tabs",
                      {
                        model: {
                          value: _vm.curTab,
                          callback: function ($$v) {
                            _vm.curTab = $$v
                          },
                          expression: "curTab",
                        },
                      },
                      [
                        _c(
                          "el-tab-pane",
                          {
                            attrs: { label: "平台短信配置", name: "sunyunPay" },
                          },
                          [
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "loading",
                                    rawName: "v-loading",
                                    value: _vm.loading,
                                    expression: "loading",
                                  },
                                ],
                                staticClass: "content",
                              },
                              [
                                _c(
                                  "el-form",
                                  {
                                    ref: "form",
                                    attrs: {
                                      "label-width": "180px",
                                      model: _vm.form,
                                      rules: _vm.rules,
                                    },
                                  },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "是否启用" } },
                                      [
                                        _c("el-switch", {
                                          model: {
                                            value: _vm.form.isStop,
                                            callback: function ($$v) {
                                              _vm.$set(_vm.form, "isStop", $$v)
                                            },
                                            expression: "form.isStop",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "短信平台",
                                          prop: "smsConfigId",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            attrs: {
                                              placeholder: "请选择短信平台",
                                              clearable: "",
                                            },
                                            model: {
                                              value: _vm.form.smsConfigId,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "smsConfigId",
                                                  $$v
                                                )
                                              },
                                              expression: "form.smsConfigId",
                                            },
                                          },
                                          _vm._l(
                                            _vm.dict.type.sms_channel_type,
                                            function (dict) {
                                              return _c("el-option", {
                                                key: dict.value,
                                                attrs: {
                                                  label: dict.label,
                                                  value: dict.value,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "用户名",
                                          prop: "userId",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            placeholder: "请输入用户名",
                                          },
                                          model: {
                                            value: _vm.form.userId,
                                            callback: function ($$v) {
                                              _vm.$set(_vm.form, "userId", $$v)
                                            },
                                            expression: "form.userId",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "密码",
                                          prop: "userPwd",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: { placeholder: "请输入密码" },
                                          model: {
                                            value: _vm.form.userPwd,
                                            callback: function ($$v) {
                                              _vm.$set(_vm.form, "userPwd", $$v)
                                            },
                                            expression: "form.userPwd",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "x-bc" },
                                      [
                                        _c(
                                          "el-form-item",
                                          [
                                            _c(
                                              "el-button",
                                              {
                                                attrs: {
                                                  type: "primary",
                                                  size: "mini",
                                                },
                                                on: { click: _vm.submitForm },
                                              },
                                              [_vm._v("保存 ")]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-form-item",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: _vm.form.smsChannelType,
                                                expression:
                                                  "form.smsChannelType",
                                              },
                                            ],
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "skipBtn" },
                                              [
                                                _vm.form.smsChannelType == 1
                                                  ? _c(
                                                      "a",
                                                      {
                                                        attrs: {
                                                          href: "http://web.900112.com/login.html",
                                                          target: "_blank",
                                                        },
                                                      },
                                                      [_vm._v("点击进入平台")]
                                                    )
                                                  : _c(
                                                      "a",
                                                      {
                                                        attrs: {
                                                          href: "https://account.aliyun.com/login/login.htm",
                                                          target: "_blank",
                                                        },
                                                      },
                                                      [_vm._v("点击进入平台")]
                                                    ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }