<template>
  <!-- 商户列表 -->
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options"></TablePage>
  </div>
</template>

<script>
import TablePagination from '@/components/tablePage/tablePagination'
import seniorSearch from '@/views/components/seniorSearch' //高级搜索
import cardTitleCom from '@/views/components/cardTitleCom' //卡片标题
import TablePage from '@/components/tablePage/index.vue'
import { msgDetailList } from '@/api/tenant/config/platSmsHistory'

export default {
  name: 'TenantList',
  components: { TablePage, cardTitleCom, seniorSearch, TablePagination },
  dicts: ['tenant_service_state'],
  data () {
    return {
      options: {
        listNo: true,
        loading: true,
        pagination: {
          total: 0,
          pageNum: 1,
          pageSize: 15
        },
        getListApi: msgDetailList,
        title: '商户员工',
        rowKey: 'createTime',
        search: [
          {
            label: '日期',
            type: 'datetimerange',
            model: '',
            filterFnc: ([a, b] = []) => ({ beginBillDate: a, endBillDate: b })
          },
          {
            keyIndex: 0,
            format: 'datetimerange',
            type: 'timeRange',
            defaultValue: 'day',
            data: ['day', 'yesterday', 'week', 'lastWeek', 'month', 'lastMonth']
          },
          {
            type: 'input',
            tip: '全部/商户号/商户名称',
            model: '',
            filter: 'query'
          },
          { type: 'button', tip: '查询', btnType: 'primary', click: 'search' },
          { type: 'button', tip: '重置', click: 'reset' }
        ],
        columns: [
          { prop: 'tenantNo', label: '商户号' },
          { prop: 'tenantName', label: '商户名称' },
          { prop: 'templateName', label: '短信模板' },
          {
            prop: 'sendCount',
            activeText (row) {
              return row.sendCount + '条'
            },
            label: '发送条数'
          },
          { prop: 'sendContent', label: '发送内容' },
          { prop: 'createTime', label: '发送时间' },
          { prop: 'sendTel', label: '电话' }
        ],
        list: []
      }
    }
  }
}
</script>
