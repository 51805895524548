<template>
  <!-- 平台支付通道 -->
  <div class="platPayChannel">
    <!-- 使用门店支付通道设置 -->
    <PayChannel routerName="HandlePlatPayChannelDetail"/>
  </div>
</template>

<script>
import PayChannel from '@/views/shop/base/payChannel'
export default {
  name:"PlatPayChannel",
  components:{ PayChannel },
}
</script>

<style>

</style>