<template>
  <!-- 系统参数列表 -->
  <div class="wrap" v-loading="loading">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent" />

    <el-dialog :visible.sync="openDialog" width="30%" :title="title">
      <img width="100%" :src="QRCodeImg" alt="" />
    </el-dialog>
  </div>
</template>

<script>
import TablePagination from '@/components/tablePage/tablePagination'
import seniorSearch from '@/views/components/seniorSearch' //高级搜索
import cardTitleCom from '@/views/components/cardTitleCom' //卡片标题
import { getToken } from '@/utils/auth'
import {
  wxAppList,
  uploadCode,
  TrialQRCode,
  submitCodeAudit,
  appletRelease,
  queryAuditStatus,
  OfficialCode,
  delWXApp
} from '@/api/tenant/config/wxaManage'
import { setPrivacySetting } from '@/api/tenant/config/wxaTemplate'
import TablePage from '@/components/tablePage/index.vue'
import handleCompareDialog from '@/views/tenant/product/compare/components/handleCompareDialog' // 操作(新增/编辑)系统产品功能弹窗
import CDialog from '@/components/dialogTemplate/dialog/index.vue'
import { AllTenantAPI } from '@/api/system/tenant'
import { getDicts } from '@/api/system/dict/data'
import axios from 'axios'

export default {
  name: 'TenantList',
  components: {
    TablePage,
    cardTitleCom,
    seniorSearch,
    TablePagination,
    handleCompareDialog,
    CDialog
  },
  dicts: ['wx_app_type'],
  data () {
    return {
      openDialog: false,
      title: '',
      QRCodeImg: '',
      options: {
        loading: true,
        check: [],
        mutiSelect: true,
        radioSelect: false,
        pagination: {
          total: 0,
          pageNum: 1,
          pageSize: 15
        },
        defaultBody: {},
        getListApi: wxAppList,
        title: '产品列表',
        rowKey: 'wxAppId',
        search: [
          {
            type: 'local',
            label: '商户',
            model: '',
            filter: 'tenantId',
            option: {
              clearable: true,
              remote: AllTenantAPI,
              downLoad: 15,
              label: 'tenantName',
              value: 'tenantId',
              filterable: false,
              labels: [
                { title: '编号', label: 'tenantNo' },
                { title: '名称', label: 'tenantName' }
              ]
            }
          },
          {
            type: 'local',
            label: '类型',
            model: '',
            filter: 'wxAppType',
            option: {
              clearable: true,
              remote: getDicts,
              remoteBody: 'wx_app_type',
              label: 'dictLabel',
              value: 'dictValue'
            }
          },
          { type: 'button', tip: '查询', btnType: 'primary', click: 'search' },
          { type: 'button', tip: '重置', click: 'reset' }
        ],
        buttons: [
          {
            click: 'upload',
            label: '上传代码',
            type: 'success',
            icon: 'el-icon-upload'
          },
          {
            click: 'experience',
            label: '获取小程序体验码',
            type: 'primary',
            icon: 'el-icon-refresh',
            num: 1
          },
          {
            click: 'examine',
            label: '代码审核发布',
            type: 'warning',
            icon: 'el-icon-position'
          },
          {
            click: 'release',
            label: '发布小程序',
            type: 'primary'
          },
          {
            click: 'user',
            label: '配置用户隐私',
            type: 'primary'
          },
          {
            click: 'status',
            label: '查询审核状态',
            type: 'info',
            icon: 'el-icon-search',
            num: 1
          },
          {
            click: 'get',
            label: '获取小程序正式码',
            type: 'primary',
            icon: 'el-icon-refresh',
            num: 1
          },
          {
            click: 'del',
            label: '删除',
            type: 'danger',
            icon: 'el-icon-delete',
            num: 1
          },
          {
            click: 'refresh',
            right: true,
            label: '刷新',
            icon: 'el-icon-refresh-right',
            type: ''
          }
        ],
        columns: [
          { prop: 'tenantNo', label: '商户号', minWidth: '260' },
          { prop: 'tenantName', label: '商户名称', minWidth: '200' },
          {
            prop: 'wxAppType',
            type: 'dicts',
            name: 'wx_app_type',
            label: '小程序类型',
            minWidth: '200'
          },
          { prop: 'wxAppId', label: '小程序AppID', minWidth: '200' },
          { prop: 'nickName', label: '小程序版本', minWidth: '200' },
          { prop: 'nickName', label: '最新版本' },
          { prop: 'wxAuthorizerCode', label: '授权code', minWidth: '200' },
          {
            prop: 'wxAuthorizerRefreshToken',
            label: '刷新token',
            minWidth: '200'
          },
          {
            prop: 'wxAuthorizerAccessToken',
            label: '调用token',
            minWidth: '200'
          },
          {
            prop: 'wxAuthorizerJssdkToken',
            label: 'JSSDK_token',
            minWidth: '200'
          },
          {
            prop: 'wxAuthorizerCreateTime',
            label: 'token更新时间',
            minWidth: '200'
          },
          { prop: 'createBy', label: '创建人' },
          { prop: 'createTime', label: '创建时间', minWidth: '200' }
        ],
        list: []
      },
      loading: false
    }
  },
  methods: {
    update () {
      this.$refs.tablePage.getList()
    },

    async handleEvent (type, row) {
      console.log(this.options.check)
      switch (type) {
        case 'upload':
          this.loading = true
          let errmsg = ''
          for(let i = 0; i < this.options.check.length; i ++){
            if(this.options.check[i].wxAppType === 1) continue
              //配置文件
              let ext_json = {
                extEnable: true,
                extAppid: this.options.check[i].wxAppId,
                ext: {
                  requiredPrivateInfos: ['getLocation', 'chooseAddress']
                }
              }
              let uploadInfo = {}
              try{
                console.log('//////')
                uploadInfo = await axios({
                  url:process.env.VUE_APP_BASE_API + '/api/system/applet/commit',
                  method: 'post',
                  headers: {
                    Authorization: 'Bearer ' + getToken()
                  },
                  data:{
                    ext_json: JSON.stringify(ext_json),
                    user_version: 'V1.0',
                    user_desc: '上传代码',
                    appId: this.options.check[i].wxAppId
                  }
                })
              }catch(err){
                uploadInfo = err
              }
              uploadInfo = uploadInfo.data
              // try{
              //   uploadInfo = await uploadCode({
              //   ext_json: JSON.stringify(ext_json),
              //   user_version: 'V1.0',
              //   user_desc: '上传代码',
              //   appId: this.options.check[i].wxAppId
              // })}catch(err){
              //   uploadInfo = err
              // }
              console.log(uploadInfo,'uploadInfo')
              if (!(uploadInfo?.errcode < 40000 && uploadInfo?.errcode >= 0)) {
                errmsg += (this.options.check[i].wxAppTitle + (uploadInfo.errmsg || uploadInfo.msg)) 
              } 
          }
          console.log(errmsg,'提示')
          if (!errmsg) {
            this.$message.success('上传代码成功')
          } else {
            this.$message.error(`${errmsg}上传失败`)
          }
          this.loading = false
          // if (this.options.check[0].wxAppType === 1) {
          //   return this.$message.error('微信公众号不支持该操作')
          // }
          // this.loading = true
          // //配置文件
          // let ext_json = {
          //   extEnable: true,
          //   extAppid: this.options.check[0].wxAppId,
          //   ext: {
          //     requiredPrivateInfos: ['getLocation', 'chooseAddress']
          //   }
          // }
          // const uploadInfo = await uploadCode({
          //   ext_json: JSON.stringify(ext_json),
          //   user_version: 'V1.0',
          //   user_desc: '上传代码',
          //   appId: this.options.check[0].wxAppId
          // })
          // if (uploadInfo.errcode < 40000 && uploadInfo.errcode >= 0) {
          //   this.$message.success('上传代码成功')
          // } else {
          //   this.$message.error('上传失败')
          // }
          // this.loading = false
          break
        case 'experience':
          if (this.options.check[0].wxAppType === 1) {
            return this.$message.error('微信公众号不支持该操作')
          }
          //参数
          let query = {
            appId: this.options.check[0].wxAppId,
            path: '/pages/tabbar/home/index'
          }
          let trial = await TrialQRCode(query)
          if (trial) {
            this.QRCodeImg = trial
            this.title = '小程序体验码'
            this.openDialog = true
          }
          break
        case 'examine':
          {
            this.loading = true
            let errmsg = ''
            for(let i = 0; i < this.options.check.length; i ++){
              if(this.options.check[i].wxAppType === 1) continue
                let autdit = {}
                // try{
                //   autdit = await submitCodeAudit({
                //     appId: this.options.check[i].wxAppId
                //   })
                // }catch(err){}
                // if (!(autdit.errcode < 40000 && autdit.errcode >= 0)) {
                //   errmsg += (this.options.check[i].wxAppTitle + autdit.errmsg) 
                // } 
                try{
                  autdit = await axios({
                    url:process.env.VUE_APP_BASE_API + '/api/system/applet/submitAudit',
                    method: 'post',
                    headers: {
                      Authorization: 'Bearer ' + getToken()
                    },
                    data:{
                      appId: this.options.check[i].wxAppId
                    }
                  })
                }catch(err){
                  autdit = err
                }
                autdit = autdit?.data
                if (!(autdit?.errcode < 40000 && autdit?.errcode >= 0)) {
                  errmsg += (this.options.check[i].wxAppTitle + (autdit?.errmsg || autdit?.msg) ) 
                } 
            }
            if (!errmsg) {
              this.$message.success('代码审核发布成功')
            } else {
              this.$message.error(`${errmsg}代码审核发布失败`)
            }
            this.loading = false
            /* if (this.options.check[0].wxAppType === 1) {
              return this.$message.error('微信公众号不支持该操作')
            }
            let autdit = await submitCodeAudit({
              appId: this.options.check[0].wxAppId
            })
            this.$message.success(autdit.errmsg) */
          }
          break
        case 'release':
          {
            this.loading = true
            let errmsg = ''
            for(let i = 0; i < this.options.check.length; i ++){
              if(this.options.check[i].wxAppType === 1) continue
                let release = {}
                /* try{
                  release = await appletRelease({
                    appId: this.options.check[i].wxAppId
                  })
                }catch(err){} */
                try{
                  release = await axios({
                    url:process.env.VUE_APP_BASE_API + '/api/system/applet/release',
                    method: 'post',
                    headers: {
                      Authorization: 'Bearer ' + getToken()
                    },
                    data:{
                      appId: this.options.check[i].wxAppId
                    }
                  })
                }catch(err){
                  release = err
                }
                release = release?.data
                if (!(release?.errcode < 40000 && release?.errcode >= 0)) {
                  errmsg += (this.options.check[i].wxAppTitle + (release?.errmsg || release?.msg)) 
                } 
            }
            if (!errmsg) {
              this.$message.success('发布小程序成功')
            } else {
              this.$message.error(`${errmsg}发布小程序失败`)
            }
            this.loading = false
          }
          // if (this.options.check[0].wxAppType === 1) {
          //   return this.$message.error('微信公众号不支持该操作')
          // }
          // appletRelease({
          //   appId: this.options.check[0].wxAppId
          // }).then(res => {})
          break
        case 'user':
          await this.setPrivacy()
          break
        case 'status':
          if (this.options.check[0].wxAppType === 1) {
            return this.$message.error('微信公众号不支持该操作')
          }
          let { status } = await queryAuditStatus({
            appId: this.options.check[0].wxAppId
          })
          let message
          if (+status === 0) message = '审核成功'
          if (+status === 1) message = '审核被拒绝'
          if (+status === 2) message = '审核中'
          if (+status === 3) message = '已撤回'
          if (+status === 4) message = '审核延后'
          this.$message.success(message)
          break
        case 'get':
          if (this.options.check[0].wxAppType === 1) {
            return this.$message.error('微信公众号不支持该操作')
          }
          let code = await OfficialCode({
            appId: this.options.check[0].wxAppId
          })
          if (code) {
            this.QRCodeImg = code
            this.title = '小程序正式码'
            this.openDialog = true
          }
          break
        case 'del':
          this.$confirm(`确定删除此商户小程序`, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          })
            .then(async () => {
              await delWXApp([this.options.check[0].wxLineId])
              this.$message.success('删除成功')
              this.update()
            })
            .catch(() => {})
          break
      }
    },

    // 设置隐私
    async setPrivacy () {
      // this.loading = true
      // setPrivacySetting({
      //   appId: this.options.check[0].wxAppId,
      //   contactEmail: 'refire1987@163.com',
      //   noticeMethod: '隼云科技收集信息',
      //   setting_list: [
      //     {
      //       privacy_key: 'UserInfo',
      //       privacy_text: '用来登记会员信息'
      //     },
      //     {
      //       privacy_key: 'Location',
      //       privacy_text: '用来展示你的位置信息'
      //     },
      //     {
      //       privacy_key: 'Address',
      //       privacy_text: '用来展示你的地址'
      //     },
      //     {
      //       privacy_key: 'PhoneNumber',
      //       privacy_text: '用来查看信息'
      //     },
      //     {
      //       privacy_key: 'Album',
      //       privacy_text: '选中的照片或视频信息'
      //     },
      //     {
      //       privacy_key: 'AlbumWriteOnly',
      //       privacy_text: '相册（仅写入）权限'
      //     },
      //     {
      //       privacy_key: 'ChooseLocation',
      //       privacy_text: '选择的位置信息'
      //     }
      //   ]
      // })
      //   .then(res => {
      //     this.loading = false
      //     this.$message.success('设置成功')
      //     this.update()
      //   })
      //   .catch(err => {
      //     this.loading = false
      //     this.$message.error('设置失败')
      //   })
      this.loading = true
      let errmsg = ''
      for(let i = 0; i < this.options.check.length; i ++){
          if(this.options.check[i].wxAppType === 1) continue
          let privacy = {}
          /* try{
            privacy = await setPrivacySetting({
              appId: this.options.check[i].wxAppId,
              contactEmail: 'refire1987@163.com',
              noticeMethod: '隼云科技收集信息',
              setting_list: [
                {
                  privacy_key: 'UserInfo',
                  privacy_text: '用来登记会员信息'
                },
                {
                  privacy_key: 'Location',
                  privacy_text: '用来展示你的位置信息'
                },
                {
                  privacy_key: 'Address',
                  privacy_text: '用来展示你的地址'
                },
                {
                  privacy_key: 'PhoneNumber',
                  privacy_text: '用来查看信息'
                },
                {
                  privacy_key: 'Album',
                  privacy_text: '选中的照片或视频信息'
                },
                {
                  privacy_key: 'AlbumWriteOnly',
                  privacy_text: '相册（仅写入）权限'
                },
                {
                  privacy_key: 'ChooseLocation',
                  privacy_text: '选择的位置信息'
                }
              ]
            })
          }catch(err){} */
          try{
            privacy = await axios({
              url:process.env.VUE_APP_BASE_API + '/api/system/applet/setPrivacySetting',
              method: 'post',
              headers: {
                Authorization: 'Bearer ' + getToken()
              },
              data:{
                appId: this.options.check[i].wxAppId,
                contactEmail: 'refire1987@163.com',
                noticeMethod: '隼云科技收集信息',
                setting_list: [
                  {
                    privacy_key: 'UserInfo',
                    privacy_text: '用来登记会员信息'
                  },
                  {
                    privacy_key: 'Location',
                    privacy_text: '用来展示你的位置信息'
                  },
                  {
                    privacy_key: 'Address',
                    privacy_text: '用来展示你的地址'
                  },
                  {
                    privacy_key: 'PhoneNumber',
                    privacy_text: '用来查看信息'
                  },
                  {
                    privacy_key: 'Album',
                    privacy_text: '选中的照片或视频信息'
                  },
                  {
                    privacy_key: 'AlbumWriteOnly',
                    privacy_text: '相册（仅写入）权限'
                  },
                  {
                    privacy_key: 'ChooseLocation',
                    privacy_text: '选择的位置信息'
                  }]
              }
            })
            }catch(err){
              privacy = err
            }
            privacy = privacy?.data
          if (!(privacy?.errcode < 40000 && privacy?.errcode >= 0)) {
            errmsg += (this.options.check[i].wxAppTitle + (privacy?.errmsg || privacy?.msg)) 
          } 
      }
      if (!errmsg) {
        this.$message.success('设置成功')
      } else {
        this.$message.error(`${errmsg}设置失败`)
      }
      this.loading = false
    }
  }
}
</script>

<!--
<template>
  &lt;!&ndash; 微信小程序管理 &ndash;&gt;
  <div class="wxaManage" id="wrap">
    <cardTitleCom cardTitle="查询" id="search-card">
      <template slot="cardContent">
        <div class="navSearch">
          &lt;!&ndash; 高级搜索按钮 &ndash;&gt;
          <seniorSearch
            dataLabel="创建时间"
            :dropDownList="dropDownList"
            :isBillDate="false"
            :isSearchInput="true"
            @isShowHighCom="getIsShowHigh"
          />
          <div v-show="showHigh" class="marT10 x-f">
            <div class="item x-f">
              <div class="label fS14MR10">商户</div>
              <SelectFrame
                size="mini"
                ref="selectTenant"
                v-model="queryParams.tenantName"
                :tableData="tenantData"
                @getPitchId="getDiyselectId($event, 'tenantId', 'queryParams')"
                :isMoreFunc="false"
                :normalizer="normalizerTenant"
              ></SelectFrame>
            </div>
            <div class="item x-f">
              <div class="label fS14MR10">小程序类型</div>
              <el-select
                v-model="queryParams.wxAppType"
                placeholder="请选择微信App类型"
                clearable
                size="mini"
              >
                <el-option
                  v-for="dict in dict.type.wx_app_type"
                  :key="dict.value"
                  :label="dict.label"
                  :value="dict.value"
                />
              </el-select>
            </div>
          </div>
        </div>
      </template>
    </cardTitleCom>
    <cardTitleCom cardTitle="微信小程序">
      <template slot="cardContent">
        <div class="tableContent">
          <operatingButton
            :multiple="multiple"
            :isDeleteBtn="true"
            :isAddBtn="false"
            @handleDelete="clickBtn('删除')"
          >
            <template #specialDiyBtn>
              <el-button
                type="success"
                icon="el-icon-upload"
                size="mini"
                @click="clickBtn('上传代码')"
              >上传代码
              </el-button
              >
              <el-button
                type="primary"
                icon="el-icon-refresh"
                size="mini"
                @click="clickBtn('获取小程序体验码')"
              >获取小程序体验码
              </el-button
              >
              <el-button
                type="warning"
                icon="el-icon-position"
                size="mini"
                @click="clickBtn('代码审核发布')"
              >代码审核发布
              </el-button
              >
              <el-button
                type="primary"
                size="mini"
                @click="clickBtn('发布小程序')"
              >发布小程序
              </el-button>
              <el-button
                type="primary"
                size="mini"
                @click="clickBtn('用户隐私')"
              >配置用户隐私
              </el-button>
              <el-button
                type="info"
                icon="el-icon-search"
                size="mini"
                @click="clickBtn('查询审核状态')"
              >查询审核状态
              </el-button
              >
              <el-button
                type="primary"
                icon="el-icon-refresh"
                size="mini"
                @click="clickBtn('获取小程序正式码')"
              >获取小程序正式码
              </el-button
              >
            </template>
          </operatingButton>
          &lt;!&ndash; 表格区域 &ndash;&gt;
          <div class="table">
            <el-table
              :data="tableData"
              :height="tableHeight"
              :max-height="tableHeight"
              border
              style="width: 100%"
              v-loading="loading"
              ref="table"
            >
              <el-table-column width="55" align="center">
                <template v-slot="scope">
                  <el-radio v-model="curItem" :label="scope.row"
                  ><span></span
                  ></el-radio>
                </template>
              </el-table-column>
              <el-table-column
                type="index"
                label="序号"
                width="50"
                align="center"
              >
              </el-table-column>
              <el-table-column
                show-overflow-tooltip
                :prop="item.prop"
                :label="item.title"
                :width="item.width"
                v-for="item in tableFields"
                :key="item.title"
                align="center"
              >
                <template slot-scope="scope">
                  <dict-tag
                    :options="dict.type.wx_app_type"
                    :value="scope.row.wxAppType"
                    v-if="item.prop === 'wxAppType'"
                  />
                  <div v-else>{{ scope.row[item.prop] }}</div>
                </template>
              </el-table-column>
            </el-table>
            <TablePagination
              :page.sync="queryParams.pageNum"
              :limit.sync="queryParams.pageSize"
              :total="total"
              @pagination="getList"
            ></TablePagination>
          </div>
        </div>
      </template>
    </cardTitleCom>
    &lt;!&ndash; 图片放大对话框 &ndash;&gt;
    <el-dialog :visible.sync="openDialog" width="30%" :title="title">
      <img width="100%" :src="QRCodeImg" alt=""/>
    </el-dialog>
  </div>
</template>

<script>
import seniorSearch from '@/views/components/seniorSearch' //高级搜索
import TablePagination from '@/components/tablePage/tablePagination'
import OperatingButton from '@/views/components/operatingButton/index.vue'
import comBtn from '@/views/components/com-btn' //按钮区域组件
import cardTitleCom from '@/views/components/cardTitleCom' //卡片标题
import {
  wxAppList,
  uploadCode,
  TrialQRCode,
  submitCodeAudit,
  queryAuditStatus,
  OfficialCode,
  delWXApp,
  appletRelease
} from '@/api/tenant/config/wxaManage' //接口api
import { getData } from '@/utils/public'
import { getPrivacySetting } from '@/api/tenant/config/wxaTemplate' //获取下拉框数据
export default {
  name: 'wxaManage',
  components: { comBtn, cardTitleCom, TablePagination, OperatingButton, seniorSearch },
  dicts: ['wx_app_type'],
  data() {
    return {
      queryParams: {
        pageNum: 1,
        pageSize: 15,
        tenantId: undefined, //商户id
        wxAppType: undefined //小程序类型
      }, //查询
      filePath: undefined,
      total: 0,
      loading: false, //遮罩层
      tableData: [], //表格数据
      tenantData: [], //商户数据
      curItem: undefined, //当前表格选中的项
      QRCodeImg: undefined, //二维码图片
      openDialog: false, //图片弹窗开关
      title: undefined, //图片弹窗标题
      //自定义搜索框字段转换 (商户)
      normalizerTenant: {
        Id: 'tenantId',
        No: 'tenantNo',
        Name: 'tenantName'
      },
      //表格字段(默认商品表格字段)
      tableFields: [
        { prop: 'tenantNo', title: '商户号', width: '260' },
        { prop: 'tenantName', title: '商户名称', width: '200' },
        { prop: 'wxAppType', title: '小程序类型', width: '160' },
        { prop: 'wxAppId', title: '小程序AppID', width: '160' },
        { prop: 'nickName', title: '小程序版本', width: '160' },
        { prop: 'nickName', title: '最新版本' },
        { prop: 'wxAuthorizerCode', title: '授权code' },
        { prop: 'wxAuthorizerRefreshToken', title: '刷新token', width: '160' },
        { prop: 'wxAuthorizerAccessToken', title: '调用token', width: '160' },
        { prop: 'wxAuthorizerJssdkToken', title: 'JSSDK_token', width: '160' },
        {
          prop: 'wxAuthorizerCreateTime',
          title: 'token更新时间',
          width: '160'
        },
        { prop: 'createBy', title: '创建人' },
        { prop: 'createTime', title: '创建时间' }
      ],
      //搜索框搜索的条件
      dropDownList: [
        { parameter: 'wxAppType', name: '商户' }
      ],
      showHigh: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      vivwH: 0, //页面高度
      searchH: 0, //搜索框高度
      paginationH: 70 // 分页组件高度
    }
  },
  computed: {
    tableHeight() {
      return this.vivwH - this.paginationH - this.searchH - 130
    }
  },
  async created() {
    this.getList()
    this.tenantData = await getData('tenant') //获取商户数据
  },
  mounted() {
    this.$nextTick(() => {
      this.vivwH = document.getElementById('wrap').clientHeight
      this.searchH = document.getElementById('search-card').clientHeight
    })
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    handleResize() {
      this.vivwH = document.getElementById('wrap').clientHeight
      this.searchH = document.getElementById('search-card').clientHeight
    },
    //自定义下拉框选中的id值
    getDiyselectId(id, fieldName, form) {
      this[form][fieldName] = id
    },
    //点击按钮事件 type参数区分类型(新增/审核/启用/复制/删除/刷新)
    async clickBtn(type) {
      console.log(type)
      if (type === '刷新') return this.handleQuery()
      if (!this.curItem) return this.$message.error('请选择商户小程序')

      if (type === '用户隐私') {
        this.setPrivacy()
        return
      }

      if (type === '删除') {
        this.$confirm(`确定删除此商户小程序`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(async() => {
            await delWXApp([this.curItem.wxLineId])
            this.$message.success('删除成功')
            this.getList()
          })
          .catch(() => {
          })
        return
      }
      if (this.curItem.wxAppType == 1) {
        return this.$message.error('微信公众号不支持该操作')
      }
      if (type === '上传代码') {
        this.loading = true
        //配置文件
        let ext_json = {
          extEnable: true,
          extAppid: this.curItem.wxAppId,
          ext: {
            requiredPrivateInfos: ['getLocation', 'chooseAddress']
          }
        }
        let query = {
          ext_json: JSON.stringify(ext_json),
          user_version: 'V1.0',
          user_desc: '上传代码',
          appId: this.curItem.wxAppId
        }
        const res = await uploadCode(query)
        if (res.errcode < 40000 && res.errcode >= 0) {
          this.$message.success('上传代码成功')
        } else {
          this.$message.error('上传失败')
        }
        this.loading = false
      } else if (type === '获取小程序体验码') {
        //参数
        let query = {
          appId: this.curItem.wxAppId,
          path: '/pages/tabbar/home/index'
        }
        let res = await TrialQRCode(query)
        if (res) {
          this.QRCodeImg = res
          this.title = '小程序体验码'
          this.openDialog = true
        }
      } else if (type === '代码审核发布') {
        let res = await submitCodeAudit({
          appId: this.curItem.wxAppId
        })
        this.$message.success(res.errmsg)
      } else if (type === '查询审核状态') {
        let res = await queryAuditStatus({
          appId: this.curItem.wxAppId
        })
        let message
        if (res.status == 0) message = '审核成功'
        if (res.status == 1) message = '审核被拒绝'
        if (res.status == 2) message = '审核中'
        if (res.status == 3) message = '已撤回'
        if (res.status == 4) message = '审核延后'
        this.$message.success(message)
      } else if (type === '获取小程序正式码') {
        let res = await OfficialCode({
          appId: this.curItem.wxAppId
        })
        if (res) {
          this.QRCodeImg = res
          this.title = '小程序正式码'
          this.openDialog = true
        }
      } else if (type === '发布小程序') {
        console.log('发布小程序', this.curItem)
        appletRelease({
          appId: this.curItem.wxAppId
        }).then(res => {
        })
      }
    },

    // 设置隐私
    setPrivacy() {
      this.loading = true
      getPrivacySetting({
        'appId': this.curItem.wxAppId,
        'contactWeixin': 'sunny',
        'noticeMethod': '隼云科技收集信息',
        'setting_list': [{
          'privacy_key': 'UserInfo',
          'privacy_text': '用来登记会员信息'
        },
          {
            'privacy_key': 'Location',
            'privacy_text': '用来展示你的位置信息'
          },
          {
            'privacy_key': 'Address',
            'privacy_text': '用来展示你的地址'
          },
          {
            'privacy_key': 'PhoneNumber',
            'privacy_text': '用来查看信息'
          },
          {
            'privacy_key': 'Album',
            'privacy_text': '选中的照片或视频信息'
          },
          {
            'privacy_key': 'AlbumWriteOnly',
            'privacy_text': '相册（仅写入）权限'
          },
          {
            'privacy_key': 'ChooseLocation',
            'privacy_text': '选择的位置信息'
          }
        ]
      }).then(res => {
        this.loading = false
        this.$message.success('设置成功')
        this.getList()
      }).catch(err => {
        this.loading = false
        this.$message.error('设置失败')
      })
    },

    //搜索
    handleQuery() {
      this.queryParams.pageNum = 1
      this.getList()
    },

    //重置搜索
    resetQuery() {
      this.queryParams = {
        pageNum: 1,
        pageSize: 15,
        tenantId: undefined, //商户id
        wxAppType: undefined //小程序类型
      } //查询
      this.getList()
    },

    //获取列表
    async getList() {
      this.loading = true
      try {
        let res = await wxAppList(this.queryParams)
        this.tableData = res.rows
        this.total = res.total
        this.loading = false
      } catch {
        this.loading = false
      }
    },

    //是否显示高级搜索
    getIsShowHigh(value) {
      this.showHigh = value
      this.$nextTick(() => {
        this.handleResize()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.wxaManage {
  height: calc(100vh - 84px);
  padding: 6px;
  background-color: #eaeaea;

  .navSearch {
    padding: 10px;

    .item {
      margin-right: 10px;

      .el-input,
      .el-select {
        width: 170px;
      }
    }
  }

  .search {
    padding: 10px;

    .item {
      margin-right: 10px;
    }

    .el-input,
    .el-select {
      width: 170px;
    }
  }

  .tableContent {
    padding: 10px;
  }
}
</style>
-->
