<template>
    <!--  微信第三方平台 -->
    <div class="wxComponent" id="wrap">
        <cardTitleCom cardTitle="基础信息" class="productMenu">
            <template slot="cardContent">
                <div class="cardContent" :style="{height: tableHeight + 'px'}">
                    <el-tabs v-model="curTab">
                        <el-tab-pane label="第三方开发平台" name="wxComponent">
                            <div class="content">
                                <el-form label-width="150px" :rules="rules" :model="form" ref="form">
                                    <el-form-item label="是否启用">
                                        <el-switch v-model="form.isStop"></el-switch>
                                    </el-form-item>
                                    <el-form-item label="平台appid" prop="componentAppid">
                                        <el-input
                                            class="inputW220"
                                            v-model="form.componentAppid"
                                            placeholder="请输入平台appid"
                                        />
                                    </el-form-item>
                                    <el-form-item label="平台appsecret" prop="componentAppsecret">
                                        <el-input
                                            class="inputW220"
                                            v-model="form.componentAppsecret"
                                            placeholder="请输入平台appsecret"
                                        />
                                    </el-form-item>
                                    <el-form-item label="平台消息加解密key" prop="componentMsgKey">
                                        <el-input
                                            class="inputW220"
                                            v-model="form.componentMsgKey"
                                            placeholder="请输入平台消息加解密key"
                                        />
                                    </el-form-item>
                                    <el-form-item label="平台消息校验token" prop="componentMsgToken">
                                        <el-input
                                            class="inputW220"
                                            v-model="form.componentMsgToken"
                                            placeholder="请输入平台消息校验token"
                                        />
                                    </el-form-item>
                                    <el-form-item label="小程序服务器域名" prop="setServerDomain">
                                       <div class="x-f">
                                           <el-input
                                               class="inputW220"
                                               v-model="form.setServerDomain"
                                               placeholder="以https://开头，可填写5个域名，域名间请用;分割"
                                           />
                                           <span class="explain">以https://开头，可填写5个域名，域名间请用;分割</span>
                                       </div>
                                    </el-form-item>
                                    <el-form-item label="小程序业务域名" prop="setWebviewDomain">
                                       <div class="x-f">
                                           <el-input
                                               class="inputW220"
                                               v-model="form.setWebviewDomain"
                                               placeholder="以https://开头，可填写5个域名，域名间请用;分割"
                                           />
                                           <span class="explain">以https://开头，可填写5个域名，域名间请用;分割</span>
                                       </div>
                                    </el-form-item>
                                    <el-form-item label="微信服务器域名" prop="setWxServiceDomain">
                                        <el-input
                                            class="inputW220"
                                            v-model="form.setWxServiceDomain"
                                            placeholder="请输入微信服务器域名"
                                        />
                                    </el-form-item>
                                    <el-form-item label="微信卡包会员域名" prop="setWebviewDomain">
                                        <el-input
                                            class="inputW220"
                                            v-model="form.setWxViph5Domain"
                                            placeholder="请输入微信卡包会员域名"
                                        />
                                    </el-form-item>
                                    <el-form-item>
                                        <el-button type="primary" size="mini" @click="submitForm"
                                        >保存
                                        </el-button
                                        >
                                    </el-form-item>
                                </el-form>
                            </div>
                        </el-tab-pane>
                    </el-tabs>
                </div>
            </template>
        </cardTitleCom>
    </div>
</template>

<script>
import { wxComponentDetail, handlewxComponent } from '@/api/tenant/config/wxComponent' //接口api
import cardTitleCom from '@/views/components/cardTitleCom' //卡片标题
export default {
    name: 'WXComponent',
    components: { cardTitleCom },
    data() {
        return {
            form: {
                isStop: true, //是否启用
                componentAppid: undefined, //平台appid
                componentAppsecret: undefined, //平台appsecret
                componentMsgKey: undefined, //平台消息加解密key
                componentMsgToken: undefined, //平台消息校验token
                setServerDomain: undefined, // 服务器域名
                setWebviewDomain: undefined, // 小程序业务域名
                setWxViph5Domain: undefined, // 微信卡包会员域名
                setWxServiceDomain: undefined, // 微信服务器域名

            },
            curTab: 'wxComponent', //当前标签页
            rules: {//表单校验
                componentAppid: [
                    { required: true, message: '请输入平台appid', trigger: 'blur' }
                ],
                componentAppsecret: [
                    { required: true, message: '请输入平台appsecret', trigger: 'blur' }
                ],
                componentMsgKey: [
                    { required: true, message: '请输入平台消息加解密key', trigger: 'blur' }
                ],
                componentMsgToken: [
                    { required: true, message: '请输入平台消息校验token', trigger: 'blur' }
                ],
                setServerDomain: [
                    { required: true, message: '请输入小程序服务器域名', trigger: 'blur' }
                ],
                setWebviewDomain: [
                    { required: true, message: '请输入小程序业务域名', trigger: 'blur' }
                ],
                setWxViph5Domain: [
                    { required: true, message: '请输入微信卡包会员域名', trigger: 'blur' }
                ],
                setWxServiceDomain: [
                    { required: true, message: '请输入微信服务器域名', trigger: 'blur' }
                ]
            },
            vivwH: 0, //页面高度
        }
    },
    computed:{
        tableHeight () {
            return this.vivwH
        }
    },
    created() {
        this.getDetail()
    },
    mounted() {
        this.$nextTick(() => {
            this.vivwH = document.getElementById('wrap').clientHeight
        })
        window.addEventListener('resize', this.handleResize)
    },
    beforeDestroy () {
        window.removeEventListener('resize', this.handleResize)
    },
    methods: {
        handleResize () {
            this.vivwH = document.getElementById('wrap').clientHeight
        },
        //获取详情
        async getDetail() {
            const res = await wxComponentDetail()
            if (res.data) this.form = res.data
        },
        //提交表单
        submitForm() {
            this.$refs['form'].validate(async valid => {
                if (valid) {
                    try {
                        await handlewxComponent(this.form)
                        await this.getDetail()
                        this.$message.success('操作成功')
                    } catch {
                        this.$message.error('操作失败')
                    }
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.wxComponent {
    height: calc(100vh - 84px);
    padding: 6px;
    background-color: #eaeaea;

    .cardContent {
        padding: 20px;

        .content {
            width: 1200px;
            display: flex;
            justify-content: center;
        }

    }
}
.inputW220{
    width: 500px;
}
.explain{
    color: #c4c4c4;
    font-size: 13px;
    margin-left: 10px;
}
</style>
