var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "handlePlatSmsTemplateDialog" },
    [
      _c(
        "el-dialog",
        {
          directives: [
            {
              name: "dialogDrag",
              rawName: "v-dialogDrag",
              value: true,
              expression: "true",
            },
          ],
          attrs: {
            title: _vm.title,
            visible: _vm.openDialog,
            width: "30%",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.openDialog = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticClass: "content",
            },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    "label-width": "80px",
                    model: _vm.form,
                    rules: _vm.rules,
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "模版类型", prop: "templateId" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            placeholder: "请选择模版类型",
                            clearable: "",
                          },
                          on: {
                            blur: function ($event) {
                              return _vm.$refs.form.validateField("templateId")
                            },
                          },
                          model: {
                            value: _vm.form.templateId,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "templateId", $$v)
                            },
                            expression: "form.templateId",
                          },
                        },
                        _vm._l(_vm.dict.type.sms_template_id, function (dict) {
                          return _c("el-option", {
                            key: dict.value,
                            attrs: { label: dict.label, value: dict.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "模板名称", prop: "templateName" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入模板名称" },
                        model: {
                          value: _vm.form.templateName,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "templateName", $$v)
                          },
                          expression: "form.templateName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "模板内容", prop: "templateContent" } },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "请输入模板内容",
                          type: "textarea",
                          rows: 6,
                        },
                        model: {
                          value: _vm.form.templateContent,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "templateContent", $$v)
                          },
                          expression: "form.templateContent",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "短信签名", prop: "signature" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入短信签名" },
                        model: {
                          value: _vm.form.signature,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "signature", $$v)
                          },
                          expression: "form.signature",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "备注" } },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "请输入备注",
                          type: "textarea",
                          rows: 4,
                        },
                        model: {
                          value: _vm.form.remark,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "remark", $$v)
                          },
                          expression: "form.remark",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "btn" },
                [
                  _c("el-button", { on: { click: _vm.handleClose } }, [
                    _vm._v("取 消"),
                  ]),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.submitForm },
                    },
                    [_vm._v("确 定")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }