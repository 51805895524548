<template>
  <!--  平台支付配置 -->
  <div class="platPay" id="wrap">
    <cardTitleCom cardTitle="基础信息" class="productMenu">
      <template slot="cardContent">
        <div class="cardContent" :style="{height: tableHeight + 'px'}">
          <el-tabs v-model="form.payModeId">
            <el-tab-pane label="隼云付" name="8"></el-tab-pane>
            <el-tab-pane label="银行转账" name="10"></el-tab-pane>
          </el-tabs>
          <div class="content">
            <el-form
              label-width="180px"
              v-loading="loading"
              ref="form"
              :model="form"
            >
              <!-- 隼云付 -->
              <div class="sunyunPay" v-if="form.payModeId === '8'">
                <el-form-item label="是否启用">
                  <el-switch v-model="form.isStop"></el-switch>
                </el-form-item>
                <el-form-item label="支付通道" prop="payChannelId">
                  <el-select
                    v-model="form.payChannelId"
                    placeholder="请选择支付通道"
                    clearable
                    @change="payChannelChange"
                  >
                    <el-option
                      v-for="item in channelList"
                      :key="item.payChannelId"
                      :label="item.payChannelName"
                      :value="item.payChannelId"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item
                  label="接入类型"
                  prop="payChannelType"
                  v-show="form.payChannelType"
                >
                  <el-select
                    v-model="form.payChannelType"
                    placeholder="请选择接入类型"
                    clearable
                    disabled
                  >
                    <el-option
                      v-for="dict in dict.type.shop_pay_type"
                      :key="dict.value"
                      :label="dict.label"
                      :value="parseInt(dict.value)"
                    />
                  </el-select>
                </el-form-item>
                <div class="payInfo">
                  <el-form-item
                    label="第三方支付AppID"
                    v-show="
                      form.payChannelType == 1 || form.payChannelType == 2
                    "
                    prop="merchantAppId"
                  >
                    <el-input
                      v-model="form.merchantAppId"
                      placeholder="请输入第三方支付AppID"
                      disabled
                    ></el-input>
                  </el-form-item>
                  <el-form-item
                    label="第三方支付密钥(Secret)"
                    v-show="
                      form.payChannelType == 1 || form.payChannelType == 2
                    "
                    prop="merchantKey"
                  >
                    <el-input
                      v-model="form.merchantKey"
                      placeholder="请输入第三方支付密钥(Secret)"
                      disabled
                    ></el-input>
                  </el-form-item>
                  <el-form-item
                    label="第三方支付商户号"
                    v-show="
                      form.payChannelType == 1 || form.payChannelType == 2
                    "
                    prop="merchantNo"
                  >
                    <el-input
                      v-model="form.merchantNo"
                      placeholder="请输入第三方支付商户号"
                      disabled
                    ></el-input>
                  </el-form-item>
                  <el-form-item
                    label="第三方支付子商户AppID"
                    v-show="form.payChannelType == 2"
                    prop="merchantSubAppId"
                  >
                    <el-input
                      v-model="form.merchantSubAppId"
                      placeholder="请输入第三方支付子商户AppID"
                      disabled
                    ></el-input>
                  </el-form-item>
                  <el-form-item
                    label="第三方支付子商户号"
                    v-show="form.payChannelType == 2"
                    prop="merchantSubNo"
                  >
                    <el-input
                      v-model="form.merchantSubNo"
                      placeholder="请输入第三方支付子商户号"
                      disabled
                    ></el-input>
                  </el-form-item>

                  <el-form-item
                    label="支付宝账号"
                    v-show="
                      form.payChannelType == 3 || form.payChannelType == 4
                    "
                    prop="merchantNo"
                  >
                    <el-input
                      v-model="form.merchantNo"
                      placeholder="请输入支付宝账号"
                      disabled
                    ></el-input>
                  </el-form-item>
                  <el-form-item
                    label="支付宝AppID"
                    v-show="
                      form.payChannelType == 3 || form.payChannelType == 4
                    "
                    prop="merchantAppId"
                  >
                    <el-input
                      v-model="form.merchantAppId"
                      placeholder="请输入支付宝AppID"
                      disabled
                    ></el-input>
                  </el-form-item>
                  <el-form-item
                    label="子商户AppToken"
                    v-show="form.payChannelType == 4"
                    prop="merchantKey"
                  >
                    <el-input
                      v-model="form.merchantKey"
                      placeholder="请输入子商户AppToken"
                      disabled
                    ></el-input>
                  </el-form-item>
                  <el-form-item
                    label="支付宝公有密钥"
                    v-show="
                      form.payChannelType == 3 || form.payChannelType == 4
                    "
                    prop="merchantPublicKey"
                  >
                    <el-input
                      v-model="form.merchantPublicKey"
                      placeholder="请输入支付宝公有密钥"
                      disabled
                    ></el-input>
                  </el-form-item>
                  <el-form-item
                    label="客户私有密钥"
                    v-show="
                      form.payChannelType == 3 || form.payChannelType == 4
                    "
                    prop="merchantPrivateKey"
                  >
                    <el-input
                      v-model="form.merchantPrivateKey"
                      placeholder="请输入客户私有密钥"
                      disabled
                    ></el-input>
                  </el-form-item>
                  <el-form-item
                    label="商户号"
                    v-show="form.payChannelType == 5"
                    prop="merchantNo"
                  >
                    <el-input
                      v-model="form.merchantNo"
                      placeholder="请输入商户号"
                      disabled
                    ></el-input>
                  </el-form-item>
                  <el-form-item
                    label="终端号"
                    v-show="form.payChannelType == 5"
                    prop="merchantTerminalNo"
                  >
                    <el-input
                      v-model="form.merchantTerminalNo"
                      placeholder="请输入终端号"
                      disabled
                    ></el-input>
                  </el-form-item>
                  <el-form-item
                    label="授权码"
                    v-show="form.payChannelType == 5"
                    prop="merchantKey"
                  >
                    <el-input
                      v-model="form.merchantKey"
                      placeholder="请输入授权码"
                      disabled
                    ></el-input>
                  </el-form-item>
                  <el-form-item v-show="form.payChannelType">
                    <el-button type="primary" size="mini" @click="submitForm"
                    >保存
                    </el-button
                    >
                  </el-form-item>
                </div>
              </div>
              <!-- 银行转账 -->
              <div class="bankTransfer" v-else>
                <el-form-item label="是否启用">
                  <el-switch v-model="form.isStop"></el-switch>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" size="mini" @click="submitForm">保存</el-button>
                </el-form-item>
              </div>
            </el-form>
          </div>
        </div>
      </template>
    </cardTitleCom>
  </div>
</template>

<script>
import cardTitleCom from '@/views/components/cardTitleCom' //卡片标题
import {
  handlePayConfig, //新增/修改
  payConfigDetail, //详情
  payChannelList //支付通道数据
} from '@/api/tenant/config/platPay' //接口api

export default {
  name: 'ConfigPlatPay',
  components: { cardTitleCom },
  dicts: ['shop_pay_type'],
  data() {
    return {
      loading: true, //遮罩层
      channelList: [], // 支付通道列表
      form: {
        isStop: false, //是否启用
        payChannelId: undefined, //支付通道
        payModeId: '8' //支付方式
      },
      vivwH: 0 //页面高度
    }
  },
  watch: {
    //侦听支付方式 获取详情
    'form.payModeId': {
      handler(val) {
        if (val) {
          if (val === '8') this.getChannelList(Number(val))   //获取支付通道数据
          this.getPayConfigDetail(Number(val))
        }
      }
    }
  },
  computed: {
    tableHeight() {
      return this.vivwH
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.vivwH = document.getElementById('wrap').clientHeight
    })
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
  },
  async created() {
    await this.getChannelList(Number(this.form.payModeId))   //获取支付通道数据
    this.getPayConfigDetail(Number(this.form.payModeId))
  },
  methods: {
    handleResize() {
      this.vivwH = document.getElementById('wrap').clientHeight
    },
    // 获取支付通道数据
    async getChannelList(payModeId) {
      try {
        let res = await payChannelList(payModeId)
        this.channelList = res.data.data
      } catch {
      }
    },
    // 获取支付方式详情
    async getPayConfigDetail(payModeId) {
      this.loading = true
      try {
        let res = await payConfigDetail(payModeId)
        if (res.data) {
          res.data.payModeId = String(res.data.payModeId)
          this.form = res.data
          this.payChannelChange(this.form.payChannelId)
        } else {
          this.form.isStop = false //初始化回显
          this.form.payChannelId = undefined
        }
        this.loading = false
      } catch {
        this.loading = false
      }
    },
    //更改选中的支付通道
    payChannelChange(payChannelId) {
      this.channelList.forEach(item => {
        if (item.payChannelId === payChannelId) {
          this.form = {
            ...item,
            ...this.form
          }
        }
      })
    },
    //提交表单
    async submitForm() {
      try {
        await handlePayConfig(this.form)
        this.$message.success('操作成功')
      } catch {
        this.$message.error('操作失败')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.platPay {
  height: calc(100vh - 84px);
  padding: 6px;
  background-color: #eaeaea;

  .cardContent {
    padding: 20px;

    .content {
      width: 900px;
      display: flex;
      justify-content: center;
    }

    .el-form {
      width: 700px;
    }
  }
}
</style>
