<template>
  <!-- 操作(新增/编辑)短信模板弹窗 -->
  <div class="handlePlatSmsTemplateDialog">
    <el-dialog
      :title="title"
      :visible.sync="openDialog"
      v-dialogDrag="true"
      width="30%"
      :before-close="handleClose"
    >
      <div class="content" v-loading="loading">
        <el-form label-width="80px" :model="form" :rules="rules" ref="form">
          <el-form-item label="模版类型" prop="templateId">
            <el-select
              v-model="form.templateId"
              placeholder="请选择模版类型"
              clearable
              @blur="$refs.form.validateField('templateId')"
            >
              <el-option
                v-for="dict in dict.type.sms_template_id"
                :key="dict.value"
                :label="dict.label"
                :value="dict.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="模板名称" prop="templateName">
            <el-input
              v-model="form.templateName"
              placeholder="请输入模板名称"
            />
          </el-form-item>
          <el-form-item label="模板内容" prop="templateContent">
            <el-input
              v-model="form.templateContent"
              placeholder="请输入模板内容"
              type="textarea"
              :rows="6"
            />
          </el-form-item>
          <el-form-item label="短信签名" prop="signature">
            <el-input v-model="form.signature" placeholder="请输入短信签名" />
          </el-form-item>
          <el-form-item label="备注">
            <el-input
              v-model="form.remark"
              placeholder="请输入备注"
              type="textarea"
              :rows="4"
            />
          </el-form-item>
        </el-form>
        <div class="btn">
          <el-button @click="handleClose">取 消</el-button>
          <el-button type="primary" @click="submitForm">确 定</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  saveMsgTemplate,//新增
  updateMsgTemplate,//修改
  msgTemplateDetail,//详情
} from '@/api/tenant/config/platSmsTemplate' //接口api
export default {
  name: 'HandlePlatSmsTemplateDialog',
  props: {
    //弹窗开关
    openDialog: {
      type: Boolean,
      default: false
    },
    //当前模板id
    curTemplateId:{}
  },
  dicts: ['sms_template_id'],
  data () {
    return {
      loading: false, //遮罩层
      title: '新增短信模板',
      form: {
        templateId: undefined, //模板id
        templateName: undefined, //模板名称
        templateContent: undefined, //模板内容
        signature: undefined, //模板签名
        remark: undefined //备注
       },//表单
      // 表单校验
      rules: {
        templateId: [
          {
            required: true,
            message: '请选择模版类型',
            trigger: ['blur', 'change']
          }
        ],
        templateName: [
          { required: true, message: '请输入模板名称', trigger: 'blur' }
        ],
        templateContent: [
          { required: true, message: '请输入模板内容', trigger: 'blur' }
        ],
        signature: [
          { required: true, message: '请输入模板签名', trigger: 'blur' }
        ]
      }
    }
  },
  watch:{
    openDialog:{
     async handler (val) {
        if(val){
          if(this.curTemplateId !== undefined){
            this.loading = true
            this.title = '编辑短信模板'
             const res = await msgTemplateDetail(this.curTemplateId)
             this.form = res.data
            this.loading = false
          }else{
            this.title = '新增短信模板'
          }
        }
      },
      immediate:true
    }
  },
  methods: {
    //弹窗关闭的回调
    handleClose () {
      this.form = {
        templateId: undefined, //模板id
        templateName: undefined, //模板名称
        templateContent: undefined, //模板内容
        signature: undefined, //模板签名
        remark: undefined //备注
       }//表单
      this.resetForm('form')
      this.$emit('update:openDialog', false)
    },
    //提交表单
    submitForm () {
      this.$refs['form'].validate(async valid => {
        if (valid) {
          if (this.title === '新增短信模板') {
            try {
              await saveMsgTemplate(this.form)
              this.$message.success('新增成功')
              this.handleClose()
              this.$emit('getList')
            } catch {}
          } else {
            try {
              await updateMsgTemplate(this.form)
              this.$message.success('编辑成功')
              this.handleClose()
              this.$emit('getList')
            } catch {}
          }
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.handlePlatSmsTemplateDialog {
  ::v-deep .el-dialog {
    max-width: 600px !important;
    min-width: 600px !important;
  }
  ::v-deep .el-dialog__body {
    padding: 10px 20px 0px !important;
  }
  .btn {
    text-align: right;
    padding: 10px 0;
  }
}
</style>
