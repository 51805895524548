<template>
  <!--  平台短信配置 -->
  <div class="platSms" id="wrap">
    <cardTitleCom cardTitle="基础信息" class="productMenu">
      <template #cardContent>
        <div class="cardContent" :style="{height: tableHeight + 'px'}">
          <el-tabs v-model="curTab">
            <el-tab-pane label="平台短信配置" name="sunyunPay">
              <div class="content" v-loading="loading">
                <el-form label-width="180px" :model="form" :rules="rules" ref="form">
                  <el-form-item label="是否启用">
                    <el-switch v-model="form.isStop"></el-switch>
                  </el-form-item>
                  <el-form-item label="短信平台" prop="smsConfigId">
                    <el-select
                      v-model="form.smsConfigId"
                      placeholder="请选择短信平台"
                      clearable
                    >
                      <el-option
                        v-for="dict in dict.type.sms_channel_type"
                        :key="dict.value"
                        :label="dict.label"
                        :value="dict.value"
                      />
                    </el-select>
                  </el-form-item>
                  <el-form-item label="用户名" prop="userId">
                    <el-input
                      v-model="form.userId"
                      placeholder="请输入用户名"
                    />
                  </el-form-item>
                  <el-form-item label="密码" prop="userPwd">
                    <el-input
                      v-model="form.userPwd"
                      placeholder="请输入密码"
                    />
                  </el-form-item>
                  <div class="x-bc">
                    <el-form-item>
                      <el-button type="primary" size="mini" @click="submitForm"
                      >保存
                      </el-button
                      >
                    </el-form-item>
                    <el-form-item v-show="form.smsChannelType">
                      <div class="skipBtn">
                        <a v-if="form.smsChannelType == 1"
                           href="http://web.900112.com/login.html" target="_blank"
                        >点击进入平台</a>
                        <a v-else href="https://account.aliyun.com/login/login.htm"
                           target="_blank"
                        >点击进入平台</a>
                      </div>
                    </el-form-item>
                  </div>
                </el-form>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </template>
    </cardTitleCom>
  </div>
</template>

<script>
import cardTitleCom from '@/views/components/cardTitleCom' //卡片标题
import {
  handleMsgConfig,//新增/修改
  smsConfigDetail//详情
} from '@/api/tenant/config/platSms'

export default {
  name: 'ConfigPlatSms',
  components: { cardTitleCom },
  dicts: ['shop_pay_type', 'sms_channel_type'],
  data() {
    return {
      loading: false,//遮罩层
      form: {
        smsConfigId: '1',//短信平台
        isStop: false,//是否启用
        userId: undefined,//用户账号
        userPwd: undefined//用户密码
      },
      curTab: 'sunyunPay', //当前标签页
      // 表单校验
      rules: {
        smsConfigId: [
          { required: true, message: '请选择短信平台', trigger: ['blur', 'change'] }
        ],
        userId: [
          { required: true, message: '请输入用户账号', trigger: 'blur' }
        ],
        userPwd: [
          { required: true, message: '请输入用户账号', trigger: 'blur' }
        ]
      },
      vivwH: 0 //页面高度
    }
  },
  computed: {
    tableHeight() {
      return this.vivwH
    }
  },
  watch: {
    //侦听短信平台 获取详情
    'form.smsConfigId': {
      handler(val) {
        if (val) {
          this.getMsgConfigDetail(val)
        }
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.vivwH = document.getElementById('wrap').clientHeight
    })
    window.addEventListener('resize', this.handleResize)

    this.getMsgConfigDetail(this.form.smsConfigId)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    handleResize() {
      this.vivwH = document.getElementById('wrap').clientHeight
    },
    //获取平台短信配置详情
    async getMsgConfigDetail(smsConfigId) {
      this.loading = true
      try {
        let res = await smsConfigDetail(smsConfigId)
        if (res.data) this.form = res.data
        this.loading = false
      } catch {
        this.loading = false
      }
    },
    //提交表单
    submitForm() {
      this.$refs['form'].validate(async valid => {
        if (valid) {
          try {
            await handleMsgConfig(this.form)
            this.$message.success('操作成功')
          } catch {
            this.$message.error('操作失败')
          }
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.platSms {
  height: calc(100vh - 84px);
  padding: 6px;
  background-color: #eaeaea;

  .cardContent {
    padding: 20px;

    .content {
      width: 900px;
      display: flex;
      justify-content: center;
    }

    .el-form {
      width: 700px;
    }

    .skipBtn {
      color: #1890ff;
      cursor: pointer;
    }
  }
}
</style>
