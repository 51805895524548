<template>
  <!-- 平台短信模板 -->
  <div class="platSmsTemplate" id="wrap">
    <cardTitleCom cardTitle="查询" id="search-card">
      <template slot="cardContent">
        <div class="navSearch">
          <seniorSearch
            v-model="queryParams"
            :getList="getList"
            :dropDownList="dropDownList"
            :isSearchInput="true"
            :isAdvancedSearch="false"
            :isBillDate="false"
            isSearchInputTitle="模板名称"
          />
        </div>
      </template>
    </cardTitleCom>
    <cardTitleCom cardTitle="短信模板列表">
      <template slot="cardContent">
        <div class="tableContent">
          <!-- 操作按钮 -->
          <operatingButton
            :getList="getList"
            :multiple="multiple"
            :isDeleteBtn="true"
            :isCopyBtn="true"
            :isEnableBtn="true"
            @handleAdd="clickBtn('新增')"
            @handleDelete="clickBtn('删除')"
          />
          <!-- 表格区域 -->
          <div class="table">
            <!-- 表格 -->
            <el-table
              :data="tableData"
              :height="tableHeight"
              :max-height="tableHeight"
              border
              style="width: 100%"
              v-loading="loading"
              ref="table"
              @selection-change="handleSelectionChange"
            >
              <el-table-column
                type="selection"
                width="50"
                align="center"
              ></el-table-column>
              <el-table-column
                type="index"
                label="序号"
                width="50"
                align="center"
              >
              </el-table-column>
              <el-table-column
                prop="templateName"
                label="模板名称"
                align="center"
                width="200"
              >
                <template slot-scope="scope">
                  <el-button
                    type="text"
                    @click.native.prevent="
                      clickBtn('编辑', scope.row.templateId)
                    "
                    >{{ scope.row.templateName }}
                  </el-button>
                </template>
              </el-table-column>
              <el-table-column
                show-overflow-tooltip
                :prop="item.prop"
                :label="item.title"
                :width="item.width"
                v-for="item in tableFields"
                :key="item.title"
                align="center"
              >
              </el-table-column>
            </el-table>
            <TablePagination
              :page.sync="queryParams.pageNum"
              :limit.sync="queryParams.pageSize"
              :total="total"
              @pagination="getList"
            ></TablePagination>
          </div>
        </div>
      </template>
    </cardTitleCom>
    <!-- 操作(新增/编辑)短信模板弹窗 -->
    <handlePlatSmsTemplateDialog
      :openDialog.sync="openDialog"
      :curTemplateId="curTemplateId"
      @getList="getList"
    />
  </div>
</template>

<script>
import {
  delMsgTemplate, //删除
  msgTemplateList //分页列表
} from '@/api/tenant/config/platSmsTemplate' //接口api
import TablePagination from '@/components/tablePage/tablePagination'
import seniorSearch from '@/views/components/seniorSearch' //高级搜索
import OperatingButton from '@/views/components/operatingButton/index.vue'
import handlePlatSmsTemplateDialog from '@/views/tenant/config/platSmsTemplate/components/handlePlatSmsTemplateDialog' //操作(新增/编辑)短信模板弹窗
import cardTitleCom from '@/views/components/cardTitleCom' //卡片标题
export default {
  name: 'PlatSmsTemplate',
  components: {
    cardTitleCom,
    OperatingButton,
    handlePlatSmsTemplateDialog,
    seniorSearch,
    TablePagination
  },
  data () {
    return {
      loading: false, //遮罩层
      curTemplateId: undefined, //当前模板id
      queryParams: {
        pageNum: 1,
        pageSize: 15,
        templateName: undefined //模版名称
      }, //查询
      tableData: [], //表格数据
      total: 0,
      openDialog: false, //操作(新增/编辑)短信模板弹窗开关
      ids: [], // 选中数组id
      names: [], //选中的名字数组
      //表格字段(默认商品表格字段)
      tableFields: [
        { prop: 'templateContent', title: '模板内容', width: '200' },
        { prop: 'signature', title: '短信签名', width: '200' },
        { prop: 'remark', title: '备注', width: '200' },
        { prop: 'createBy', title: '创建人', width: '150' },
        { prop: 'createTime', title: '创建时间', width: '160' },
        { prop: 'updateBy', title: '修改人', width: '150' },
        { prop: 'updateTime', title: '修改时间', width: '160' }
      ],
      //搜索框搜索的条件
      dropDownList: [
        { parameter: 'query', name: '全部' },
        { parameter: 'templateNames', name: '模板名称' }
      ],
      // 非多个禁用
      multiple: true,
      vivwH: 0, //页面高度
      searchH: 0, //搜索框高度
      paginationH: 70 // 分页组件高度
    }
  },
  computed: {
    tableHeight () {
      return this.vivwH - this.paginationH - this.searchH - 120
    }
  },
  created () {
    this.getList()
  },
  mounted () {
    this.$nextTick(() => {
      this.vivwH = document.getElementById('wrap').clientHeight
      this.searchH = document.getElementById('search-card').clientHeight
    })
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    handleResize () {
      this.vivwH = document.getElementById('wrap').clientHeight
      this.searchH = document.getElementById('search-card').clientHeight
    },
    //搜索
    handleQuery () {
      this.queryParams.pageNum = 1
      this.getList()
    },
    //获取列表
    async getList () {
      this.loading = true
      try {
        let res = await msgTemplateList(this.queryParams)
        this.tableData = res.rows
        this.total = res.total
        this.loading = false
      } catch {
        this.loading = false
      }
    },
    //表格选中的数据
    handleSelectionChange (selection) {
      console.log('selection', selection)
      this.ids = selection.map(item => item.templateId)
      this.names = selection.map(item => item.noticeTitle)
      this.selectionNotice = selection
    },
    //点击按钮事件 type参数区分类型(新增/审核/启用/复制/删除/刷新)
    async clickBtn (type, templateId) {
      if (type === '新增') {
        this.curTemplateId = undefined
        this.openDialog = true
      } else if (type === '编辑') {
        this.curTemplateId = templateId
        this.openDialog = true
      } else if (type === '删除') {
        if (this.ids.length <= 0) return this.$message.error('请选择短信模板')
        this.$confirm(`确定删除${this.names.join(',')}短信模板`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(async () => {
            await delMsgTemplate(this.ids)
            this.$message.success('删除成功')
            this.getList()
          })
          .catch(() => {})
      } else {
        this.handleQuery()
      }
    },
    //是否显示高级搜索
    getIsShowHigh (value) {
      this.showHigh = value
      this.$nextTick(() => {
        this.handleResize()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.platSmsTemplate {
  height: calc(100vh - 84px);
  padding: 6px;
  background-color: #eaeaea;

  .navSearch {
    padding: 10px;

    .item {
      margin-right: 10px;

      .el-input,
      .el-select {
        width: 170px;
      }
    }
  }

  .navSearch {
    padding: 10px;

    .el-input {
      width: 200px;
      margin-right: 10px;
    }
  }

  .tableContent {
    padding: 10px 10px 0 10px;
  }
}
</style>
