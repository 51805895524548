<template>
  <div class="wrap" id="wrap">
    <cardTitleCom cardTitle="查询" id="search-card">
      <template slot="cardContent">
        <div class="navSearch">
          <el-form :model="queryParams" ref="queryForm" size="small" :inline="true" v-show="showSearch"
            label-width="68px">
            <div class="x-b">
              <div>
                <el-form-item label="商户" prop="tenantId">
                  <SelectLocal v-model="queryParams.tenantId" :option="{
                      data: AllTenantList,
                      clearable: true,
                      downLoad: 15,
                      label: 'tenantName',
                      value: 'tenantId',
                      filterable: false,
                      labels: [
                        { title: '编号', label: 'tenantNo' },
                        { title: '名称', label: 'tenantName' }
                      ]
                    }" />
                </el-form-item>
                <el-form-item label="系统模块" prop="title">
                  <el-input v-model="queryParams.title" placeholder="请输入系统模块" clearable
                    @keyup.enter.native="handleQuery" size="mini" style="width: 193px" />
                </el-form-item>
                <el-form-item label="操作人员" prop="operatorUser">
                  <el-input v-model="queryParams.operatorUser" placeholder="请输入操作人员" clearable
                    @keyup.enter.native="handleQuery" size="mini" style="width: 193px" />
                </el-form-item>
                <el-form-item label="类型" prop="businessType">
                  <el-select v-model="queryParams.businessType" placeholder="操作类型" clearable size="mini">
                    <el-option v-for="dict in dict.type.sys_oper_type" :key="dict.value" :label="dict.label"
                      :value="dict.value" />
                  </el-select>
                </el-form-item>
                <el-form-item label="状态" prop="status">
                  <el-select v-model="queryParams.status" placeholder="操作状态" clearable size="mini" style="width: 193px">
                    <el-option v-for="dict in dict.type.sys_common_status" :key="dict.value" :label="dict.label"
                      :value="dict.value" />
                  </el-select>
                </el-form-item>
                <el-form-item label="操作时间">
                  <el-date-picker v-model="dateRange" size="mini" value-format="yyyy-MM-dd" type="daterange"
                    range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期"
                    style="width: 240px"></el-date-picker>
                </el-form-item>
              </div>
              <div class="x-start big">
                <el-form-item>
                  <el-button type="primary" size="mini" @click="handleQuery">查询</el-button>
                  <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
                </el-form-item>
              </div>
            </div>
          </el-form>
        </div>
      </template>
    </cardTitleCom>

    <cardTitleCom cardTitle="操作日志" class="brandInfo">
      <template slot="cardContent">
        <div class="tableContent">
          <!-- 操作按钮 -->
          <operatingButton :getList="getList" :isAddBtn="false" :isDeleteBtn="false" :isExportBtn="false"
            :isRefreshBtn="true" :multiple="multiple" @handleDelete="handleDelete">
            <template #specialDiyBtn>
              <el-button type="danger" icon="el-icon-delete" size="mini" :disabled="multiple"
                @click="handleDelete">删除</el-button>
              <!--   v-hasPermi="['monitor:sys:log:remove']" -->

              <el-button class="iconfont icon-qingchu1" size="mini" @click="handleClean" type="success">
                清空
              </el-button>
              <!-- v-hasPermi="['monitor:sys:log:remove']" -->
            </template>
          </operatingButton>

          <!-- <el-button
          type="warning"
          plain
          icon="el-icon-download"
          size="mini"
          @click="handleExport"
          v-hasPermi="['monitor:sys:log:export']"
          >导出</el-button
        > -->

          <el-table ref="tables" v-loading="loading" :data="list" @selection-change="handleSelectionChange"
            :default-sort="defaultSort" @sort-change="handleSortChange" :height="tableHeight" :max-height="tableHeight"
            border>
            <el-table-column type="selection" width="55" align="center" />
            <el-table-column label="商户号" align="center" prop="tenantNo" />
            <el-table-column label="商户名称" align="center" prop="tenantName" />
            <el-table-column label="日志编号" align="center" prop="sysLogId" />
            <el-table-column label="系统模块" align="center" prop="title" />
            <el-table-column label="操作类型" align="center" prop="businessType">
              <template slot-scope="scope">
                <dict-tag :options="dict.type.sys_oper_type" :value="scope.row.businessType" />
              </template>
            </el-table-column>
            <el-table-column label="请求方式" align="center" prop="requestMethod" />
            <el-table-column label="操作人员" align="center" prop="operatorUser" width="100" :show-overflow-tooltip="true"
              sortable="custom" :sort-orders="['descending', 'ascending']" />
            <el-table-column label="操作地址" align="center" prop="requestIp" width="130" :show-overflow-tooltip="true" />
            <el-table-column label="操作地点" align="center" prop="requestLocation" :show-overflow-tooltip="true" />
            <el-table-column label="操作状态" align="center" prop="status">
              <template slot-scope="scope">
                <dict-tag :options="dict.type.sys_common_status" :value="scope.row.status" />
              </template>
            </el-table-column>
            <el-table-column label="操作日期" align="center" prop="operatorTime" sortable="custom"
              :sort-orders="['descending', 'ascending']" width="180">
              <template slot-scope="scope">
                <span>{{ parseTime(scope.row.operatorTime) }}</span>
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
              <template slot-scope="scope">
                <el-button size="mini" type="text" icon="el-icon-view" @click="handleView(scope.row, scope.index)"
                  v-hasPermi="['monitor:sys:log:query']">详细</el-button>
              </template>
            </el-table-column>
          </el-table>
          <pagination v-show="total > 0" :total="total" :page.sync="queryParams.pageNum"
            :limit.sync="queryParams.pageSize" @pagination="getList" />
        </div>
      </template>
    </cardTitleCom>

    <!-- <right-toolbar
        :showSearch.sync="showSearch"
        @queryTable="getList"
      ></right-toolbar>
   -->

    <!-- 操作日志详细 -->
    <!-- <el-dialog
      title="操作日志详细"
      :visible.sync="open"
      width="700px"
      append-to-body
    >
      <el-form ref="form" :model="form" label-width="100px" size="mini">
        <el-row>
          <el-col :span="12">
            <el-form-item label="操作模块："
              >{{ form.title }} / {{ typeFormat(form) }}</el-form-item
            >
            <el-form-item label="登录信息："
              >{{ form.operatorUser }} / {{ form.requestIp }} /
              {{ form.requestLocation }}</el-form-item
            >
          </el-col>
          <el-col :span="12">
            <el-form-item label="请求接口地址：">{{
              form.requestUrl
            }}</el-form-item>
            <el-form-item label="请求方式：">{{
              form.requestMethod
            }}</el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="操作方法：">{{
              form.invokeMethod
            }}</el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="请求参数：">{{
              form.requestParam
            }}</el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="返回参数：">{{
              form.jsonResult
            }}</el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="操作状态：">
              <div v-if="form.status === 0">正常</div>
              <div v-else-if="form.status === 1">失败</div>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="操作时间：">{{
              parseTime(form.operatorTime)
            }}</el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="异常信息：" v-if="form.status === 1">{{
              form.errorMsg
            }}</el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="open = false">关 闭</el-button>
      </div>
    </el-dialog> -->
    <c-dialog :title="title" :width="600" :showDialog.sync="open">
      <template #content>
        <el-form ref="form" :model="form" label-width="100px" size="mini">
          <el-row>
            <el-col :span="12">
              <el-form-item label="操作模块：">{{ form.title }} / {{ typeFormat(form) }}</el-form-item>
              <el-form-item label="登录信息：">{{ form.operatorUser }} / {{ form.requestIp }} /
                {{ form.requestLocation }}</el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="请求接口地址：">{{
                form.requestUrl
              }}</el-form-item>
              <el-form-item label="请求方式：">{{
                form.requestMethod
              }}</el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="操作方法：">{{
                form.invokeMethod
              }}</el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="请求参数：">{{
                form.requestParam
              }}</el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="返回参数：">{{
                form.jsonResult
              }}</el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="操作状态：">
                <div v-if="form.status === 0">正常</div>
                <div v-else-if="form.status === 1">失败</div>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="操作时间：">{{
                parseTime(form.operatorTime)
              }}</el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="异常信息：" v-if="form.status === 1">{{
                form.errorMsg
              }}</el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </template>
      <template #buttonList>
        <el-button @click="open = false">关 闭</el-button>
      </template>
    </c-dialog>
  </div>
</template>

<script>
import { AllTenantAPI } from '@/api/system/tenant'
import SelectLocal from '@/components/tablePage/select/select-local/index.vue'
import { listTenantPage, delSyslog, deleteByTenantId } from '@/api/monitor/syslog'
import cardTitleCom from '@/views/components/cardTitleCom' //卡片标题
import seniorSearch from '@/views/components/seniorSearch' //高级搜索
import operatingButton from '@/views/components/operatingButton' //操作按钮
import bottomPage from '@/views/components/bottomPage' //底部分页
import CDialog from '@/components/dialogTemplate/dialog/index.vue'
export default {
  name: 'Operlog',
  dicts: ['sys_oper_type', 'sys_common_status'],
  components: {
    cardTitleCom,
    seniorSearch,
    operatingButton,
    bottomPage,
    CDialog,
    SelectLocal
  },
  data() {
    return {
      AllTenantList: [],
      // 弹出层标题
      title: '',
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 表格数据
      list: [],
      // 是否显示弹出层
      open: false,
      // 日期范围
      dateRange: [],
      // 默认排序
      defaultSort: { prop: 'operatorTime', order: 'descending' },
      // 表单参数
      form: {},
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 15,
        title: undefined,
        operatorUser: undefined,
        businessType: undefined,
        status: undefined
      },
      vivwH: 0, //页面高度
      searchH: 0, //搜索框高度
      paginationH: 60
    }
  },
  async created() {
    const res = await AllTenantAPI()
    this.AllTenantList = res.data || []
    this.getList()
  },
  computed: {
    tableHeight() {
      return this.vivwH - this.paginationH - this.searchH - 128
    }
  },

  mounted() {
    this.$nextTick(() => {
      this.vivwH = document.getElementById('wrap').clientHeight
      console.log('wrap', this.vivwH)
      this.searchH = document.getElementById('search-card').clientHeight
      console.log('searchH', this.searchH)
    })
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    handleResize() {
      this.vivwH = document.getElementById('wrap').clientHeight
      this.searchH = document.getElementById('search-card').clientHeight
    },
    /** 查询登录日志 */
    getList() {
      this.loading = true
      listTenantPage(this.addDateRange(this.queryParams, this.dateRange)).then(
        response => {
          this.list = response.rows
          this.total = response.total
          this.loading = false
        }
      )
    },
    // 操作日志类型字典翻译
    typeFormat(row, column) {
      return this.selectDictLabel(
        this.dict.type.sys_oper_type,
        row.businessType
      )
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.dateRange = []
      this.resetForm('queryForm')
      this.$refs.tables.sort(this.defaultSort.prop, this.defaultSort.order)
      this.handleQuery()
    },
    /** 多选框选中数据 */
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.sysLogId)
      this.multiple = !selection.length
    },
    /** 排序触发事件 */
    handleSortChange(column, prop, order) {
      this.queryParams.orderByColumn = column.prop
      this.queryParams.isAsc = column.order
      this.getList()
    },
    /** 详细按钮操作 */
    handleView(row) {
      this.title = '操作日志详细'
      this.open = true
      this.form = row
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      const sysLogIds = this.ids
      this.$modal
        .confirm('是否确认删除日志编号为"' + sysLogIds + '"的数据项？')
        .then(function () {
          return delSyslog(sysLogIds)
        })
        .then(() => {
          this.getList()
          this.$modal.msgSuccess('删除成功')
        })
        .catch(() => { })
    },
    /** 清空按钮操作 */
    handleClean() {
      this.$modal
        .confirm('是否确认清空所有操作日志数据项？')
        .then(function () {
          return deleteByTenantId()
        })
        .then(() => {
          this.getList()
          this.$modal.msgSuccess('清空成功')
        })
        .catch(() => { })
    },
    /** 导出按钮操作 */
    handleExport() {
      this.download(
        '/api/system/monitor/operate/log/export',
        {
          ...this.queryParams
        },
        `operlog_${new Date().getTime()}.xlsx`
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  padding: 10px 10px 0 10px;
  background-color: #eaeaea;
  height: calc(100vh - 84px);
  // 搜索
  .navSearch {
    padding: 10px 0px 10px 20px;
    //筛选条件
    .searchCriteria {
      padding-top: 10px;
      .selectFrameUp {
        display: inline-block;
        margin-right: 15px;
      }
    }
  }
  .brandInfo {
    height: calc(100% - 100px);
    //源单信息表格
    .tableContent {
      margin: 6px 10px 10px 10px;
    }
  }
}
.el-form-item--small.el-form-item {
  margin-bottom: 0px;
}
::v-deep .el-button--mini {
  font-size: 11px;
}
.big {
  width: 170px;
}
</style>
