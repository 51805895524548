<template>
  <!-- 系统参数列表 -->
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent">
    </TablePage>

    <c-dialog
      :title="title"
      :showDialog.sync="open"
      :width="500"
      v-loading="loading"
    >
      <template #content>
        <el-form ref="form" :model="form" :rules="rules" label-width="80px">
          <el-form-item label="参数名称" prop="configName">
            <el-input
              size="mini"
              v-model="form.configName"
              placeholder="请输入参数名称"
            />
          </el-form-item>
          <el-form-item label="参数键名" prop="configKey">
            <el-input
              size="mini"
              v-model="form.configKey"
              placeholder="请输入参数键名"
            />
          </el-form-item>
          <el-form-item label="参数键值" prop="configValue">
            <el-input
              v-model="form.configValue"
              placeholder="请输入参数键值"
            ></el-input>
            <el-input
              size="mini"
              class="disabled-input"
              v-model="form.productName"
              style="width: 220px"
              placeholder="请输入内容"
            >
              <i
                slot="suffix"
                class="el-icon-more more"
                @click="showProduct"
              ></i>
            </el-input>
          </el-form-item>
          <el-form-item label="系统内置" prop="configType">
            <el-radio-group v-model="form.configType">
              <el-radio label="Y">是</el-radio>
              <el-radio label="N">否</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="备注" prop="remark">
            <el-input
              size="mini"
              v-model="form.remark"
              type="textarea"
              placeholder="请输入内容"
            />
          </el-form-item>
        </el-form>
      </template>
      <template #buttonList>
        <el-button size="mini" @click="cancel">取 消</el-button>
        <el-button size="mini" type="primary" @click="submitForm"
          >确 定</el-button
        >
      </template>
    </c-dialog>

    <selectProductDialog
      :openDialog.sync="openSelectProductDialog"
      :isRadio="true"
      :productTypes="productTypes"
      @select="selectSms"
      :isSunvunApp="isSunvunApp"
      :status="0"
      v-loading="loading"
    />

    <c-dialog
      title="回收站"
      :width="1000"
      :showDialog.sync="recycleBinDialog"
      v-loading="loading"
    >
      <template #content>
        <el-table
          class="marT20"
          :data="recycleBinList"
          style="width: 100%"
          @selection-change="recycleBinSelectionChange"
          height="500"
          border
        >
          <el-table-column align="center" type="selection" width="55">
          </el-table-column>
          <el-table-column
            align="center"
            prop="configId"
            label="参数主键"
            width="180"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="configKey"
            label="参数名"
            width="180"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="configValue"
            label="参数键值"
            width="180"
          >
          </el-table-column>
          <el-table-column align="center" prop="remark" label="备注">
          </el-table-column>
          <el-table-column align="center" prop="createTime" label="创建时间">
          </el-table-column>
        </el-table>
        <TablePagination
          :page.sync="dialogQueryParams.pageNum"
          :limit.sync="dialogQueryParams.pageSize"
          :total="dialogTotal"
          @pagination="getList"
        ></TablePagination>
      </template>
      <template #buttonList>
        <el-button size="mini" @click="closeRestoreFn">取 消</el-button>
        <el-button size="mini" type="primary" @click="restoreFn"
          >还 原</el-button
        >
      </template>
    </c-dialog>
  </div>
</template>

<script>
import TablePagination from '@/components/tablePage/tablePagination'
import seniorSearch from '@/views/components/seniorSearch' //高级搜索
import cardTitleCom from '@/views/components/cardTitleCom' //卡片标题
import {
  listConfig,
  updateConfig,
  addConfig,
  getConfig,
  delConfig,
  onRecycle
} from '@/api/system/config'
import TablePage from '@/components/tablePage/index.vue'
import CDialog from '@/components/dialogTemplate/dialog/index.vue'
import selectProductDialog from '@/views/components/selectProductDialog.vue'

export default {
  name: 'TenantList',
  components: {
    TablePage,
    cardTitleCom,
    seniorSearch,
    TablePagination,
    CDialog,
    selectProductDialog
  },
  data () {
    return {
      options: {
        loading: true,
        check: [],
        mutiSelect: true,
        pagination: {
          total: 0,
          pageNum: 1,
          pageSize: 15
        },
        defaultBody: {},
        getListApi: listConfig,
        title: '产品列表',
        rowKey: 'configId',
        search: [
          {
            type: 'input',
            tip: '请输入参数名称',
            model: '',
            filter: 'configName'
          },
          { type: 'button', tip: '查询', btnType: 'primary', click: 'search' },
          { type: 'button', tip: '重置', click: 'reset' }
        ],
        buttons: [
          {
            click: 'add',
            type: 'primary',
            label: '新增',
            icon: 'el-icon-plus'
          },
          {
            click: 'edit',
            label: '修改',
            type: 'success',
            icon: 'el-icon-edit',
            num: 1
          },
          // {
          //   click: 'start',
          //   label: '启用',
          //   alertText: '确认要启用选中方案吗？',
          //   btnType: 'dropdown',
          //   other: [
          //     { click: 'close', label: '禁用', alertText: '确认要禁用选中方案吗？' }
          //   ],
          //   disabled: () => !this.options?.check?.length
          // },
          {
            click: 'del',
            label: '删除',
            type: 'danger',
            icon: 'el-icon-delete',
            btnType: 'dropdown',
            other: [{ click: 'recycle', label: '回收站' }]
          },
          {
            click: 'refresh',
            right: true,
            label: '刷新',
            icon: 'el-icon-refresh-right',
            type: ''
          }
        ],
        columns: [
          { prop: 'configId', label: '参数主键', width: '80px' },
          { prop: 'configKey', label: '参数名' },
          { prop: 'configValue', label: '参数键值' },
          {
            prop: 'configType',
            formatter: x => {
              return x == 'Y' ? '是' : '否'
            },
            label: '系统内置'
          },
          { prop: 'remark', label: '备注' },
          { prop: 'createTime', label: '创建时间' }
        ],
        list: []
      },

      recycleBinDialog: false,
      dialogTotal: 0,
      dialogQueryParams: {
        pageSize: 15,
        pageNum: 1,
        delFlag: 1
      },

      title: '',
      open: false,
      form: {},
      // 表单校验
      rules: {
        configName: [
          { required: true, message: '参数名称不能为空', trigger: 'blur' }
        ],
        configKey: [
          { required: true, message: '参数键名不能为空', trigger: 'blur' }
        ],
        configValue: [
          { required: true, message: '参数键值不能为空', trigger: 'blur' }
        ]
      },

      openSelectProductDialog: false,
      productTypes: [2, 6], // 请求的产品类型

      isSunvunApp: true, // 是否是APP

      loading: false,

      recycleBinList: []
    }
  },
  methods: {
    /** 查询参数列表 */
    getList () {
      this.loading = true
      listConfig(this.dialogQueryParams).then(response => {
        this.recycleBinList = response.rows
        this.dialogTotal = response.total
        this.recycleBinDialog = true
        this.loading = false
      })
    },

    // 还原
    restoreFn () {
      onRecycle(this.recycleBinSelect).then(res => {
        this.$message.success('还原成功')
        this.recycleBinDialog = false
        this.update()
      })
    },
    // 回收站选择的数据
    recycleBinSelectionChange (val) {
      this.recycleBinSelect = val.map(x => x.configId)
    },
    // 关闭回收站弹框
    closeRestoreFn () {
      this.recycleBinDialog = false
    },

    /** 提交按钮 */
    submitForm: function () {
      this.$refs['form'].validate(valid => {
        if (valid) {
          this.loading = true
          if (this.form.configId) {
            updateConfig(this.form)
              .then(response => {
                this.$modal.msgSuccess('修改成功')
                this.loading = false
                this.open = false
                this.update()
              })
              .catch(err => {
                this.loading = false
              })
          } else {
            addConfig(this.form)
              .then(response => {
                this.$modal.msgSuccess('新增成功')
                this.loading = false
                this.open = false
                this.update()
              })
              .catch(err => {
                this.loading = false
              })
          }
        }
      })
    },

    // 选择产品
    selectSms (val) {
      val.forEach(item => {
        this.form.productName = item.productName
        this.form.configValue = item.productId
      })
      this.openSelectProductDialog = false
    },

    showProduct () {
      this.openSelectProductDialog = true
    },

    // 表单重置
    reset () {
      this.form = {
        configId: undefined,
        configName: undefined,
        configKey: undefined,
        configValue: undefined,
        configType: 'Y',
        productName: undefined,
        remark: undefined
      }
      this.resetForm('form')
    },

    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },

    update () {
      this.$refs.tablePage.getList()
    },

    async handleEvent (type, row) {
      switch (type) {
        case 'add':
          this.reset()
          this.open = true
          this.title = '添加参数'
          break
        case 'del':
          const names = this.options.check.map(item => item.compareFuncName)
          const ids = this.options.check.map(item => item.configId)
          this.$confirm(`确定删除${names.join(',')}功能`, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          })
            .then(async () => {
              await delConfig(ids)
              this.$message.success('删除成功')
              this.$refs.tablePage.getList()
            })
            .catch(() => {})
          break
        case 'edit':
          this.reset()
          const configId = this.options.check[0].configId
          getConfig(configId).then(response => {
            this.form = response.data
            this.open = true
            this.title = '修改参数'
          })
          break
        case 'recycle':
          this.getList()
          break
      }
    }
  }
}
</script>
