var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wxComponent", attrs: { id: "wrap" } },
    [
      _c(
        "cardTitleCom",
        { staticClass: "productMenu", attrs: { cardTitle: "基础信息" } },
        [
          _c("template", { slot: "cardContent" }, [
            _c(
              "div",
              {
                staticClass: "cardContent",
                style: { height: _vm.tableHeight + "px" },
              },
              [
                _c(
                  "el-tabs",
                  {
                    model: {
                      value: _vm.curTab,
                      callback: function ($$v) {
                        _vm.curTab = $$v
                      },
                      expression: "curTab",
                    },
                  },
                  [
                    _c(
                      "el-tab-pane",
                      {
                        attrs: { label: "第三方开发平台", name: "wxComponent" },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "content" },
                          [
                            _c(
                              "el-form",
                              {
                                ref: "form",
                                attrs: {
                                  "label-width": "150px",
                                  rules: _vm.rules,
                                  model: _vm.form,
                                },
                              },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "是否启用" } },
                                  [
                                    _c("el-switch", {
                                      model: {
                                        value: _vm.form.isStop,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "isStop", $$v)
                                        },
                                        expression: "form.isStop",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "平台appid",
                                      prop: "componentAppid",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      staticClass: "inputW220",
                                      attrs: { placeholder: "请输入平台appid" },
                                      model: {
                                        value: _vm.form.componentAppid,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "componentAppid",
                                            $$v
                                          )
                                        },
                                        expression: "form.componentAppid",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "平台appsecret",
                                      prop: "componentAppsecret",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      staticClass: "inputW220",
                                      attrs: {
                                        placeholder: "请输入平台appsecret",
                                      },
                                      model: {
                                        value: _vm.form.componentAppsecret,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "componentAppsecret",
                                            $$v
                                          )
                                        },
                                        expression: "form.componentAppsecret",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "平台消息加解密key",
                                      prop: "componentMsgKey",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      staticClass: "inputW220",
                                      attrs: {
                                        placeholder: "请输入平台消息加解密key",
                                      },
                                      model: {
                                        value: _vm.form.componentMsgKey,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "componentMsgKey",
                                            $$v
                                          )
                                        },
                                        expression: "form.componentMsgKey",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "平台消息校验token",
                                      prop: "componentMsgToken",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      staticClass: "inputW220",
                                      attrs: {
                                        placeholder: "请输入平台消息校验token",
                                      },
                                      model: {
                                        value: _vm.form.componentMsgToken,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "componentMsgToken",
                                            $$v
                                          )
                                        },
                                        expression: "form.componentMsgToken",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "小程序服务器域名",
                                      prop: "setServerDomain",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "x-f" },
                                      [
                                        _c("el-input", {
                                          staticClass: "inputW220",
                                          attrs: {
                                            placeholder:
                                              "以https://开头，可填写5个域名，域名间请用;分割",
                                          },
                                          model: {
                                            value: _vm.form.setServerDomain,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "setServerDomain",
                                                $$v
                                              )
                                            },
                                            expression: "form.setServerDomain",
                                          },
                                        }),
                                        _c("span", { staticClass: "explain" }, [
                                          _vm._v(
                                            "以https://开头，可填写5个域名，域名间请用;分割"
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "小程序业务域名",
                                      prop: "setWebviewDomain",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "x-f" },
                                      [
                                        _c("el-input", {
                                          staticClass: "inputW220",
                                          attrs: {
                                            placeholder:
                                              "以https://开头，可填写5个域名，域名间请用;分割",
                                          },
                                          model: {
                                            value: _vm.form.setWebviewDomain,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "setWebviewDomain",
                                                $$v
                                              )
                                            },
                                            expression: "form.setWebviewDomain",
                                          },
                                        }),
                                        _c("span", { staticClass: "explain" }, [
                                          _vm._v(
                                            "以https://开头，可填写5个域名，域名间请用;分割"
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "微信服务器域名",
                                      prop: "setWxServiceDomain",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      staticClass: "inputW220",
                                      attrs: {
                                        placeholder: "请输入微信服务器域名",
                                      },
                                      model: {
                                        value: _vm.form.setWxServiceDomain,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "setWxServiceDomain",
                                            $$v
                                          )
                                        },
                                        expression: "form.setWxServiceDomain",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "微信卡包会员域名",
                                      prop: "setWebviewDomain",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      staticClass: "inputW220",
                                      attrs: {
                                        placeholder: "请输入微信卡包会员域名",
                                      },
                                      model: {
                                        value: _vm.form.setWxViph5Domain,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "setWxViph5Domain",
                                            $$v
                                          )
                                        },
                                        expression: "form.setWxViph5Domain",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          type: "primary",
                                          size: "mini",
                                        },
                                        on: { click: _vm.submitForm },
                                      },
                                      [_vm._v("保存 ")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }